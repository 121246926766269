import { useEffect } from "react";
import Logo from "../assets/logo.png"
const About = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <section className="about">
      <div className="page-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-title">About</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src={Logo} alt="product" className="w-100" />
            </div>
            <div className="col-lg-6">
              <div className="about-item">
                <div className="title">Introduction</div>
                <div className="about-text">
                  Halal investments is j&k based authentic firm that provides an
                  opportunity for the people who want to invest their little
                  amount in land. halal investments help you to have halal rizq,
                  it can give you a share in the land against your amount. you
                  can earn a surplus amount without a pounce of interest. (Make
                  Your Money Work For You) the least investment amount starts
                  at one lakh only .Halal investments believes in transparency
                  where everything will be done in a crystal clear manner. you
                  won't be provided ownership of land but a share and your
                  amount will keep increasing as per the market value. You will
                  be shown the land in person.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
